import React from "react"
export function BubbleAnimation() {
    const requestRef = React.useRef<number>()
    
    const animate = () => {
        if (gCircles)
            gCircles.update();
        requestRef.current = requestAnimationFrame(animate)
    }
      
    React.useEffect(() => {
        bulle_start()
        requestRef.current = requestAnimationFrame(animate)
        return () => cancelAnimationFrame(requestRef.current!)
    }, [])
    
    return <div className='exactCenter zindexed-500'><canvas className='zindexed-0' id="cv"></canvas></div>
  }


const rand = (min: number, max: number) => { return min+(Math.random()*(max - min)) }
const randInt = (min: number, max: number) => { return Math.floor(min+(Math.random()*(max - min))) }

class Circle
{
    #colors: string[] = ['#9945FF', '#9945FF', '#7770E4', '#569BCA', '#35AFC6', '#1495F1'];
    constructor(parent: Circles) {
        this.parent = parent
        
        let v = randInt(0,this.#colors.length)
        this.color = this.#colors[v]
        this.x = rand(0, this.parent.width)
        this.r = rand(10,45)
        this.y = this.parent.height+this.r
        this.alpha = rand(0, 0.05)                       // current alpha for this instance
        this.speed = rand(0.005, 0.03)                  //alpha speed increase
        this.speed_y = rand(0.75, 1.8)                  //speed y increase
        this.speed_r = rand(-0.15,0.15)                 //speed radius circle increase
        
        this.restart()
    }

    parent: Circles 
    alpha: number
    speed: number       //<alpha
    speed_r : number    //<radius
    x: number
    y: number
    r: number
    color: string
    speed_y: number     //<y
    
    update() 
    {
        this.alpha += this.speed // update alpha
        if (this.alpha >= 1)
            this.restart()
        this.y -= this.speed_y
        if (this.r>10.0)
            this.r += (this.speed_r)

        this.parent.ctx.fillStyle = this.color;    // render this instance
        this.parent.ctx.globalAlpha = 1.0-Math.min(1.0, this.alpha)   
        this.parent.ctx.beginPath()
        this.parent.ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false)
        this.parent.ctx.fill()
    }

    restart() {
        let v = randInt(0,this.#colors.length)
        this.color = this.#colors[v]
        this.x = rand(0, this.parent.width)
        this.r = rand(10,45)
        this.y = this.parent.height+this.r
        this.alpha = rand(0, 0.05)                       // current alpha for this instance
        this.speed = rand(0.005, 0.03)                  //alpha speed increase
        this.speed_y = rand(0.75, 1.8)                  //speed y increase
        this.speed_r = rand(-0.15,0.15)                 //speed radius circle increase
    }
}

class Circles
{
    ctx: CanvasRenderingContext2D
    width: number
    height: number
    circles: Circle[] = []
    
    constructor(ctx: CanvasRenderingContext2D, w: number, h: number){
        this.ctx = ctx;
        this.width = w;
        this.height =  h;
        for(let i=0; i<150; ++i) 
        {
            this.circles.push(new Circle(this))
        }
    }
    
    update() {
        if(!this.ctx) return;
        this.ctx.globalAlpha = 0.5;
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.shadowColor = "purple";
        this.ctx.shadowBlur = 50;
        this.circles.forEach(element => {
            element.update()
        });    
    }

    resize(w:number, h:number) {
        this.width = w;
        this.height = h;
    }   
}

let gCircles : Circles | null = null

function bulle_start() {   
    let c = document.getElementById("cv") as HTMLCanvasElement
    c.height = c.clientHeight
    c.width = c.clientWidth
    if (!gCircles)
        gCircles = new Circles(c.getContext("2d") as CanvasRenderingContext2D, c.clientWidth, c.clientHeight)
}

window.onresize = function() {
    if (gCircles)
    {
        let c = document.getElementById("cv") as HTMLCanvasElement
        c.height = c.clientHeight
        c.width = c.clientWidth
        gCircles.resize(c.clientWidth, c.clientHeight)
    }
}